<template>
  <div class="responsible__card">
    <p class="responsible__header">Ответственный исполнитель</p>
    <div class="responsible__line"></div>
    <label class="responsible__label">Фамилия, имя, отчество</label>
    <input class="responsible__input" value="Константин Константинович Константинопольский">
    <label class="responsible__label">Должность</label>
    <input class="responsible__input" value="Заместитель помощника ведущего специалиста">
    <div class="responsible__div">
      <div class="responsible__div-half">
        <label class="responsible__label">Рабочий телефон (с кодом города)</label>
        <input class="responsible__input" value="+7 (812) 987-65-43">
      </div>
      <div class="responsible__div-half">
        <label class="responsible__label">Мобильный телефон</label>
        <input class="responsible__input" value="+7 (911) 456-78-89">
      </div>
    </div>
    <div class="responsible__div">
      <div class="responsible__div-half">
        <label class="responsible__label">Электронная почта / логин</label>
        <input class="responsible__input" value="konstantin@konstantin.ru">
      </div>
      <div class="responsible__div-half">
        <label class="responsible__label">Пароль</label>
        <input class="responsible__input" value="qwerty">
      </div>
    </div>
    <div class="responsible__line" style="margin: 29px 0"></div>
    <div class="responsible__rights">
      <img src="@/assets/icons/delete-round.svg" alt="delete" class="responsible__rights-img">
      <p class="responsible__rights-text">
        Всероссийский открытый конкурс для назначения стипендий Президента Российской Федерации для обучающихся за
        рубежом
      </p>
    </div>
    <div class="responsible__rights">
      <img src="@/assets/icons/delete-round.svg" alt="delete" class="responsible__rights-img">
      <p class="responsible__rights-text">
        Представление списков претендентов на стипендии Президента РФ <br> и Правительства РФ
      </p>
    </div>
    <div class="responsible__rights">
      <img src="@/assets/icons/delete-round.svg" alt="delete" class="responsible__rights-img">
      <p class="responsible__rights-text">
        Представление списков претендентов на стипендии Правительства РФ (СПО)
      </p>
    </div>
    <div class="responsible__rights">
      <img src="@/assets/icons/delete-round.svg" alt="delete" class="responsible__rights-img">
      <p class="responsible__rights-text">
        Представление списков претендентов на стипендии Президента РФ <br> и Правительства РФ (для ФОИВов)
      </p>
    </div>
    <div class="responsible__rights">
      <img src="@/assets/icons/delete-round.svg" alt="delete" class="responsible__rights-img">
      <p class="responsible__rights-text">
        Всероссийский открытый конкурс для назначения именных стипендий
      </p>
    </div>
    <button class="responsible-btn_right-add">Добавить</button>
    <div class="responsible__line" style="margin: 22px 0"></div>
    <div style="display: flex; flex-direction: row">
      <button class="responsible-btn_save-card">Сохранить</button>
      <button class="responsible-btn_delete-card">Удалить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResponsibleCard"
}
</script>

<style scoped>

</style>