<template>
  <div>
    <button class="responsible-btn_add-card">Добавить ответственного</button>
    <div class="responsible__container">
      <ResponsibleCard/>
      <ResponsibleCard/>
      <ResponsibleCard/>
    </div>
  </div>
</template>

<script>
import ResponsibleCard from "@/components/responsible/ResponsibleCard";

export default {
  name: "Responsible",
  components: {ResponsibleCard}
}
</script>

<style scoped>

</style>